import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SpeedDialAction from "@mui/material/SpeedDialAction";
//import SpeedDialAction from "./SpeedDialAction";
//import Button from '@mui/material/Button';
//import Fab from '@mui/material/Fab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import { makeStyles } from '@mui/styles';
//import Stack from '@mui/material/Stack';

import './App.css';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#25D366',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

const actions = [
  { icon: <><WhatsAppIcon sx={{mr:0.5}} /><span>Bogotá</span></>, name: "Bogota", WALink: "https://wa.link/upkwb4" },
  { icon: <><WhatsAppIcon sx={{mr:0.5}} /><span>Barranquilla</span></>, name: "Barranquilla", WALink: "https://wa.link/bijiuy" },
  { icon: <><WhatsAppIcon sx={{mr:0.5}} /><span>Cali</span></>, name: "Cali", WALink: "https://wa.link/blamrn" },
  { icon: <><WhatsAppIcon sx={{mr:0.5}} /><span>Medellín</span></>, name: "Medellín", WALink: "https://api.whatsapp.com/send?phone=573108110396" }
];

function App() {
  return (
  <ThemeProvider theme={theme}>
    <Box sx={{ height: 300, transform: "translateZ(0px)", flexGrow: 1, position: "fixed", bottom:70, left: 20, zIndex: 200000 }}>
      <SpeedDial
        //open={true}
        ariaLabel="SpeedDial basic example"
        sx={{ position: "fixed", left: 0, bottom: 0,
        '&.MuiSpeedDial-root':{
          alignItems: 'flex-start'
        },
        '.MuiSpeedDialAction-fab': {
          ':hover':{
            bgcolor:"primary.dark"
          },
          bgcolor: "primary.main",
          color: "primary.contrastText"
          //boxShadow: "none !important",
        }
         }}
        icon={<WhatsAppIcon fontSize="large"/>}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            FabProps={{variant:"extended", component:"span", size:"small" }}
            //TooltipClasses={{}}
            disableHoverListener={true}
            title="title"
            onClick={e => {
              e.stopPropagation();
              window.open(action.WALink);
            }}
            //tooltipTitle={action.name}
            //tooltipOpen
            //tooltipPlacement="right"
          />
        ))}
      </SpeedDial>
    </Box>
  </ThemeProvider>
  );
}

export default App;